import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from '../guards/GuestGuard';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import useAuth from '../hooks/useAuth';
import AuthGuard from '../guards/AuthGuard';
import PermissionGuard from '../guards/PermissionGuard';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/index" replace />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (<GuestGuard><Login /></GuestGuard>),
        },
        {
          path: 'register',
          element: (<GuestGuard><Register /></GuestGuard>),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (<AuthGuard><DashboardLayout /></AuthGuard>),
      children: [
        { element: <Navigate to="/dashboard/index" replace />, index: true },
        { path: 'index', element: <PageIndex /> },
        {
          path: 'users',
          children: [
            { element: (<PermissionGuard action="edit" model="users"><UserList /></PermissionGuard>), index: true },
            { path: 'account', element: <UserAccount /> },
            { path: 'new', element: (<PermissionGuard action="edit" model="users"><UserCreate /></PermissionGuard>) },
            { path: ':id/edit', element: (<PermissionGuard action="edit" model="users"><UserCreate /></PermissionGuard>) },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '403', element: <NoAccess /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageIndex = Loadable(lazy(() => import('../pages/PageIndex')));
const UserList = Loadable(lazy(() => import('../pages/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/user/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/user/UserCreate')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const NoAccess = Loadable(lazy(() => import('../pages/Page403')));
